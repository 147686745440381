body {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    max-width: 42rem;
    padding: 1rem;
    line-height: 1.5;
    margin: auto;
    background: #282A36;
    color: #F8F8F2;
}

h1 {
    font-size: 2rem;
    margin-bottom: 0.25rem;
    margin-top: 0;
}

h2, h3, h4 {
    color: #69FF94;
    margin: 0;
}

a, a:active, a:visited {
    color: #8BE9FD;
}

a:hover {
    color: #69FF94;
}

.header {
    margin-bottom: 2.5rem;
}

.description {
    display: flex;
    justify-content: space-between;
}

.header .description nav {
    padding: 0;
    border: none;
    min-width: 50px;
    margin: 0 0 0 15px;
}

.header .description nav ul svg {
    max-height: 20px;
}

.header nav ul.flat {
    padding: 0;
}

ul.flat {
    margin: 0;
    padding: 0;
}

.header nav ul.flat li {
    display: inline-block;
    list-style: none;
    margin-left: 0;
    margin-right: 10px;
    margin-top: 10px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.qna {
    margin-left: 1rem;
}

.qnaQuestionContainer, .qnaAnswerContainer {
    margin-left: 1rem;
    display: flex;
}

.qnaQuestion, .qnaAnswer {
    margin-right: 0.5rem;
}

p {
    font-family: monospace;
    margin: 0;
}

.loading {
  display:inline-block;
  font-family: monospace;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}